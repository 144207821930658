import React, { useContext, useState } from 'react'
import { GlobalDataContext } from '../../../context/context';
import { Link } from 'react-router-dom'
import { ButtonContent } from "../../global/boton/ButtonContent";
import MenuList from './MenuList';
import { FaAlignJustify } from 'react-icons/fa';




const HeaderOne = () => {
    const { rpdata } = useContext(GlobalDataContext);
    const [isOpen, setOpen] = useState(false);


    return (
        <div>
            <div className="bg1 p-2">
                <div className="md:w-4/5 mx-auto md:flex justify-between items-center">
                    <ul className="flex justify-center space-x-4 pb-2 md:pb-0">
                        <li className="text-white md:text-[17px] text-[13px] hidden md:block ">
                            <span className="border-r-[1px] border-white pr-3">

                                {rpdata?.dbPrincipal?.location[0]?.address}
                            </span>
                        </li>
                        <li className="text-white md:text-[17px] text-[13px] ">
                            <span className="border-r-[1px] border-white pr-3">

                                {rpdata?.dbPrincipal?.workdays[0]?.day}
                            </span>
                        </li>
                        <li className="text-white md:text-[17px] text-[13px] ">
                            <span className="pr-3">

                                {rpdata?.dbPrincipal?.workHours[0]?.hour}
                            </span>
                        </li>
                    </ul>
                    <ul className="flex justify-center space-x-7">
                        {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                            return (
                                <li key={index} className="text-white">
                                    <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                        <i
                                            className={`fab fa-${item.icon}`}
                                            aria-hidden="true"
                                        />
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            {
                rpdata?.simpleWidgets?.[3]?.activo ?
                    <div className='absolute right-[5%] left-[5%] z-50 pt-10  flex justify-center items-center'>
                        {/* logo */}
                        <div className='w-[70%] md:w-[20%] pr-3 md:pr-0'>
                            <Link to={'/'}>
                                <img
                                    src={`${rpdata?.dbPrincipal?.logo}`}
                                    alt='no found'
                                    className='w-full rounded-lg'
                                />
                            </Link>
                        </div>
                    </div>
                    :
                    <div className='absolute right-[5%] left-[5%] z-[11] pt-3  flex justify-between items-center'>
                        {/* logo */}
                        <div className='w-[80%] md:w-[30%] pr-3 md:pr-0 md:px-0 rounded-lg px-4'>
                            <Link to={'/'}>
                                <img
                                    src={`${rpdata?.dbPrincipal?.logo}`}
                                    alt='no found'
                                    className='w-full'
                                />
                            </Link>
                        </div>
                        {/* nav menu */}
                        <nav className='w-[20%] md:w-[80%] flex justify-around md:pl-20 pl-0'>
                            <div className='relative md:flex hidden'>
                                <MenuList classes={'text-white'} />
                            </div>
                            <div className='md:hidden flex bg-white p-2 rounded-sm' onClick={() => setOpen(true)}>
                                <FaAlignJustify className='textColor1 text-[38px] bg-white' />
                            </div>

                            <div className='w-auto h-full -mt-5 hidden md:flex'>
                                <ButtonContent btnStyle={'three'} btnphone={rpdata?.dbPrincipal?.phones?.[0].phone} />
                            </div>
                        </nav>
                    </div>
            }

            {isOpen ? (
                <div className="fixed z-50 top-0 right-0 left-0 bg-white text-black w-full h-screen overflow-y-auto pb-10">
                    <div className="flex justify-end w-[90%] mx-auto relative">
                        <button
                            className="font-bold text-[20px] mt-5"
                            onClick={() => setOpen(false)}
                        >
                            X
                        </button>
                    </div>
                    <div className="w-[60%] mx-auto pb-10">
                        <img
                            src={rpdata?.dbPrincipal?.logo}
                            alt="logo"
                            loading="lazy"
                            className="w-full"
                        />
                    </div>
                    <div className="w-4/5 mx-auto text-center">
                        <MenuList classes="flex-col gap-3" />
                    </div>
                    <ul className="flex justify-center space-x-7 py-10">
                        {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                            return (
                                <li key={index} className="text-black">
                                    <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                        <i
                                            className={`fab fa-${item.icon}`}
                                            aria-hidden="true"
                                        />
                                    </a>
                                </li>
                            );
                        })}
                    </ul>

                </div>
            ) : null}
        </div>
    )
}

export default HeaderOne